import React, { Component } from "react";
import Range from "./ranges/screen-range";
import Total from "./total";

export default class LandingCalculator extends Component {
        constructor() {
            super();
            this.state = {
                screenTotal:0,
                animationTotal:0,
                total:0,
            };
            this.calcScreenTotal = this.calcScreenTotal.bind(this);
            this.calcTotal = this.calcTotal.bind(this);
            this.calcAnimationTotal = this.calcAnimationTotal.bind(this);
        }
        calcTotal() {
            this.setState({
                total: this.state.screenTotal + this.state.animationTotal 
            },()=>console.log(this.state.total))
            
        }
        calcScreenTotal(value) {
            const weight = 3500;
            this.setState({
                screenTotal:value * weight ,
            })
        }
        calcAnimationTotal(value) {
            const weight = 800;
            this.setState({
                animationTotal: value* weight
            })
            
        }
        componentDidUpdate(prevProps, prevState) {
            if (prevState.screenTotal !== this.state.screenTotal || prevState.animationTotal !== this.state.animationTotal ) {
                this.calcTotal();
            }
        }
        render() {
            return(
                <>
                   <div className="calculator-wrapper">
                        <div className="ranges-wrapper">
                                <Range max ="24" value="3" method ={this.calcScreenTotal} label="Длина главной страницы в экранах: " min="2"/>
                                <Range max ="12" value="0" method ={this.calcAnimationTotal} label="Колличество анимаций" min="0"/>
                        </div>
                        <Total min="15000" value={this.state.total}/>
                   </div>
                </>
            )
        }

}