import React, { Component } from "react";

  class   Range extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value:this.props.value,
            progress:this.props.value *(100 / this.props.max),
        }
    }
    handleInputChange = (event) => {
        const newValue = event.target.value;
        this.setState({ value: newValue,
                        progress:newValue *(100 / this.props.max)
                        });
        this.props.method(event.target.value);
    }
    
    render() {
        const defValue = this.props.value;
        const max = this.props.max;
        const min = this.props.min;
        const k = 100 /max;
        const id = this.props.id;
        let progress = this.state.progress;
        let maxWidth = '100%'
        progress >=80?maxWidth = (progress-1)+"%":maxWidth = (progress -2) +"%";
        return (
            <div className="wrap">
                <p className="min">{min}</p>
                <p className="max">{max}</p>
                <div className="input-wrapper">
                    <div className="border-bottom "></div>
                    <div className="progress" style={{width:`${progress}%`,maxWidth:maxWidth}}></div>
                    <label htmlFor={id}>{this.props.label}</label>
                    <span>{this.state.value} шт</span>
                </div>
                <input type="range" 
                                max={max} 
                                defaultValue={defValue} 
                                step="1" 
                                min={min}
                                onChange={this.handleInputChange}
                                id={id}/>
            </div>
        )
    }
    };
export default Range

