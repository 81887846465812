import React from "react";
const Total = (props)=>{
    return(
        <div className="total-wrapper">
            <div className="total">
                <p className="total-label">Итого: </p>
                <p className="total-value">{(props.value>props.min)?props.value:props.min}&#8381;</p>
            </div>
            <p className="calculator_descr">
                Внимание! Данная стоимость является ориентировочной, для получения более точной ,обратитесь по одному из каналов связи , или закажите звонок.
            </p>
            <button className="btn btn-primary modal_btn"> Связаться</button>
        </div>
    )
}
export default Total